<template>
  <div class="type">
    <van-sticky :offset-top="0">
      <van-search
        v-model="keyword"
        @input="onSearch"
        placeholder="请输入搜索关键词"
      />
    </van-sticky>

    <van-tree-select
      height="90vw"
      :items="items"
      :main-active-index.sync="activeIndex"
      style="min-height: 100vh"
    >
      <template #content>
        <ul class="item flex jc-sa fww">
          <li v-for="(item, index) in productList" :key="index">
            <img :src="item.imgUrl" alt="" />
            <p class="f12">{{ item.masterName }}</p>
          </li>
        </ul>
      </template>
    </van-tree-select>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import $http from "@/utils/http";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      keyword: "", //搜索关键字
      activeIndex: -1, //左侧分类名称下标
      items: [], //左侧分类列表
      productList: [], //商品列表
      oldIndex:0,
    };
  },
  created() {
    this.getTypeList();
  },
  watch: {
    activeIndex(newIndex) {
      if(newIndex == -1){
        return false;
      }
      let categoryId = this.items[newIndex].categoryId;
      this.getProductList(categoryId);
    },
  },
  methods: {
    //搜索
    onSearch() {
      if (!this.keyword) {
        this.activeIndex = this.oldIndex;//将原来点击的分类Id 重新赋值给 acticveIndex
        let categoryId = this.items[this.oldIndex].categoryId;
        this.getProductList(categoryId);
      }else{
        let params = {
          keyword:this.keyword
        };
        $http.get('/product/search',{params}).then(res=>{
            this.productList = res.list;
        });
        this.oldIndex = this.activeIndex;
        this.activeIndex = -1;
      }
    },
    //获取分类列表
    getTypeList() {
      $http.get("/category/all").then((res) => {
        let index = this.$route.query.index ? this.$route.query.index : 0;
        this.activeIndex = index;
        this.items = res.list.map((item) => {
          return {
            ...item,
            text: item.name,
          };
        });
      });
    },
    //获取商品列表
    getProductList(categoryId) {
      let params = { categoryId: categoryId };
      $http.get("/product/list", { params }).then((res) => {
        this.productList = res.list;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.type {
  margin-bottom: 50px;
  .item {
    li {
      width: 100px;
    }

    img {
      width: 90px;
    }
  }
}
.van-search {
  background-color: #f7f8fa;
}
</style>
